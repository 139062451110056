<template>
  <layout>
    <template #secondarySidebar>
      <scheduler-nav />
    </template>

    <alert-warning v-if="! state.hasAccess" title="Access denied">
      You are not allowed to create schedules (no ownership on any machine)
    </alert-warning>

    <div v-else>
      <div class="flex flex-row justify-between items-center border-b pb-2">
        <h2 class="font-bold text-lg sm:text-2xl">Add Schedule</h2>
        <router-link :to="{ name: 'SchedulerSchedulesIndex'}" class="group">
          <div class=" flex items-center">
            <svg class="w-4 h-4 text-gray-400 group-hover:text-gray-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7"></path></svg>
            <p class="text-sm text-gray-500 group-hover:text-gray-900">Back</p>
          </div>
        </router-link>
      </div>

      <div class="space-y-6 xl:space-y-8 mt-6 xl:mt-8">
        <schedule-form
          @created="redirectOnCreated"
          :is-loading="state.isInitialLoading"
          :permissions="state.permissions"
          :selector-machines="state.machines"
        />
      </div>
    </div>

  </layout>
</template>

<script>
import { reactive } from 'vue'
import http from '@/services/http.js'
import notification from '@/services/notifications.js'
import Layout from '@/layouts/Default.vue'
import AlertWarning from '@/components/alerts/Warning.vue'
import ScheduleForm from '@/components/schedules/ScheduleForm.vue'
import SchedulerNav from '@/components/schedules/Nav.vue'
import { useRouter } from 'vue-router'

export default {
  name: 'schedules-create',

  components: {
    Layout,
    AlertWarning,
    ScheduleForm,
    SchedulerNav
  },

  setup() {
    const router = useRouter()
    const state = reactive({
      hasAccess: true,
      isInitialLoading: false,
      machines: []
    })

    const getMachines = async () => {
      state.isInitialLoading = true

      try {
        const userResponse = await http.get('user/profile')
        const isAdmin = userResponse.data.is_administrator

        const { data } = await http.get('user/machines')
        const ownerMachines = isAdmin ? data.allowed_locations : data.allowed_locations.filter(machine => machine.is_owner)
        if (ownerMachines.length === 0) {
          state.hasAccess = false
        }
        else {
          state.machines = ownerMachines
        }
      } catch (e) {
        if (e.response) {
          notification.error(e.response.data.Message)
        } else {
          notification.error('Something went wrong. Please, try again.')
        }
      }

      state.isInitialLoading = false
    }

    getMachines()

    const redirectOnCreated = () => {
      router.push({ name: 'SchedulerSchedulesIndex' })
    }

    return {
      state,
      redirectOnCreated,
    }
  }
}
</script>
